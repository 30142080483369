export default function themeComponent(theme) {
	// default font size : 16px
	// 10rem : 160px
	// 40rem : 640px
	// 60rem : 960px --> App Minimum Width
	// 51rem : 816px --> App Left Area Width
	const fontFamily = `'NanumSquareRound', 'Noto Sans KR', 'Roboto', sans-serif`;
	const mainMinWidth = '60rem'; //  960(px) / 16 = 60(rem)
	const mainMaxWidth = '120rem'; // 1920(px) / 16 = 120(rem)
	const borderRadius = theme.defaultBorderRadius;

	return {
		layout: {
			mainLayout: {
				base: {
					display: 'flex',
					flexDirection: 'column',
					backgroundColor: 'white',
					minWidth: mainMinWidth,
					maxWidth: mainMaxWidth,
					margin: '0 auto',
				},
				contents: {
					height: '100%',
				},
				appBar: {
					backgroundColor: theme.page,
				},
				subMenuCard: {
					backgroundColor: theme.page,
					width: '100%',
					minWidth: '1920px',
					paddingRight: '2.5rem',
				},
				decisionRequest: {
					padding: '0rem ',
					'& .error-row': {
						backgroundColor: theme.colors?.errorBg,
					},
					'& .warning-row': {
						backgroundColor: theme.colors?.warningBg,
					},
					'& .no-row': {
						backgroundColor: theme.colors?.grey150,
					},
					'& .error-row:hover': {
						// backgroundColor: theme.colors?.errorLight,
					},
				},
				contentBox: {
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'flex-start',
					backgroundColor: theme.page,
					display: 'flex',
					minWidth: mainMinWidth,
					maxWidth: mainMaxWidth,
					height: '100%',
					borderRadius: borderRadius,
				},
				header: {
					base: {
						base: {
							width: '1920px',
							height: '3.8125rem',
							padding: '0rem 2.5rem',
							display: 'flex',
							alignItems: 'center',
							backgroundColor: '#FFFFFF',
							justifyContent: 'space-between',
							borderBottom: `2px solid #e8e8e8`,
						},
						logoContent: {
							display: 'flex',
							alignItems: 'center',
						},
						linkContent: { display: 'flex', gap: '6.25rem' },
					},
					profile: {
						nameButton: {
							display: 'flex',
							gap: '0.625rem',
							'&:hover': { backgroundColor: 'transparent' },
						},
						content: {
							padding: '1rem',
							minWidth: '17rem',
						},
						icon: {
							display: 'flex',
							alignItems: 'center',
							px: 0.4,
						},
						memberInfo: {
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'flex-start',
						},
					},
				},
			},
		},
		uiComponent: {
			common: {
				centerAlignColumn: {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				},
				AlignRow: {
					display: 'flex',
					flexDirection: 'row',
					// alignItems: 'center',
				},
				centerAlignRow: {
					height: '2.5rem',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '5px',
					whiteSpace: 'nowrap',
				},
				buttonPadding: {
					height: '2.5rem',
					padding: '0rem 0.9375rem',
					gap: '0.625rem',
					whiteSpace: 'nowrap',
				},
				buttonPaddingHidden: {
					height: '2.5rem',
					padding: '0rem 0.9375rem',
					visibility: 'hidden',
				},
				opinionBox: {
					width: '25rem',
				},
				icon: { marginRight: '0.5rem', color: theme.colors?.grey450 },
				iconBlack: { marginRight: '0.5rem', color: '#230B34' },

				statusColor: {
					normal: {
						color: theme.successDark,
						backgroundColor: theme.successLight,
					},
					abnormal: {
						color: theme.error,
						backgroundColor: theme.error,
					},
					warning: {
						color: theme.warning,
						backgroundColor: theme.warning,
					},
				},
			},
			toggle: {
				thumb: {
					backgroundColor: theme.colors?.primaryMain,
				},
				track: {
					backgroundColor: theme.colors?.primaryMain,
				},
			},

			dialogs: {
				dialogAlert: {
					content: {
						minHeight: '5rem',
						margin: '1.4rem 0 0.6rem',
						padding: '0 auto',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						fontWeight: 500,
						lineHeight: '2rem',
						whiteSpace: 'pre-line',
						wordBreak: 'keep-all',
						'& > span': {
							lineHeight: '2rem',
						},
					},
					action: {
						padding: '1rem 4rem 2rem',
						'& > button': {
							height: '3rem',
							maxWidth: '8rem',
						},
					},
					icon: {
						position: 'absolute',
						right: '1rem',
						top: '1rem',
						p: '2rem',
					},
				},
			},
			opinionCard: {
				resultStack: {
					maxWidth: '32.5rem',
				},
				resultBoxOpinion: {
					minWidth: '16rem',
					width: '100%',
					padding: '0rem 1.25rem 1.25rem 1.25rem',
					'& .MuiPaper-root': {
						padding: '1rem',
						// minHeight: '29rem',
					},
				},
				opinionTitle: {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					// height: '3.75rem',
					width: '100%',
				},
				alignRowLeft: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-start',
					gap: '0.625rem',
					width: '100%',
				},
				alignRowRight: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-end',
					width: '100%',
				},
				libraryBtn: {
					height: '2.125rem',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '0.3125rem',
					padding: '0 0.625rem',
					'&:hover': {
						cursor: 'pointer',
					},
				},
				libraryBtnHidden: {
					visibility: 'hidden',
				},
				textField: {
					'& .MuiOutlinedInput-notchedOutline': {
						// border: `2px solid ${theme.colors?.line}`,
						border: `none`,
						borderRadius: '10px',
					},
					'& .MuiOutlinedInput-input': {
						padding: '1.25rem',
					},
				},
				textFieldDisabled: {
					'& .MuiOutlinedInput-notchedOutline': {
						border: `none`,
						borderRadius: '10px',
					},
					'& .MuiOutlinedInput-input': {
						padding: '1.25rem',
					},
					'& .MuiInputBase-input': {
						height: '100vh', //here add height of your container
					},
				},
				opinionLibraryIcon: { marginRight: '0.3125rem', color: theme.colors?.grey450 },
				opinionLibraryIconBlack: { marginRight: '0.3125rem', color: '#230B34' },
			},
			opinionLibraryCard: {
				alignRow: {
					display: 'flex',
					alignItems: 'center',
					columnGap: '0.5rem',
					marginBottom: '1rem',
				},
				infoBox: {
					height: '1rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '0.2rem',
				},
				icon: { color: theme.colors?.grey450 },
				iconBlack: { marginRight: '0.5rem', color: '#230B34' },
				header: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					width: '450px',
					height: '35px',
					padding: '1.25rem 0.5rem',
					gap: '1rem',
					borderBottom: `1px solid ${theme.colors?.grey300}`,
					marginTop: '1rem',
					backgroundColor: theme.colors?.secondaryMain,
				},
				noResult: {
					display: 'flex',
					flexDirection: 'row',
					padding: '0.5rem 0.5rem',
					gap: '1rem',
					marginTop: '1rem',
				},
				container: {
					display: 'flex',
					flexDirection: 'row',
					padding: '0.5rem 0.5rem',
					gap: '1rem',
					borderBottom: `1px solid ${theme.colors?.grey300}`,
					'&:hover': { backgroundColor: 'white', cursor: 'pointer' },
				},
				opinionLibraryList: {
					height: '42rem',
					width: '450px',
					overflow: 'auto',
					/* Firefox에 대한 스크롤바 스타일 설정 */
					'&::-webkit-scrollbar': {
						width: '8px' /* 스크롤바의 너비 */,
					},

					'&::-webkit-scrollbar-track': {
						backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
					},

					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
						borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
					},

					'&::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
					},
				},
				resultBoxOpinion: {
					display: 'flex',
					flexDirection: 'column',
					minWidth: '30.625rem',
					padding: '0rem 1.25rem',
					maxHeight: '83%',
					'& > .MuiPaper-root': {
						width: '30.625rem',
						// padding: '1rem',
					},
				},
			},
			opinionLibrarySetting: {
				header: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					width: '550px',
					height: '35px',
					padding: '1.25rem 0.5rem',
					gap: '1rem',
					borderBottom: `1px solid ${theme.colors?.grey300}`,
					marginTop: '1rem',
					backgroundColor: theme.colors?.secondaryMain,
				},
				noResult: {
					display: 'flex',
					flexDirection: 'row',
					padding: '0.5rem 0.5rem',
					gap: '1rem',
					marginTop: '1rem',
				},
				container: {
					display: 'flex',
					flexDirection: 'row',
					padding: '0.5rem 0.5rem',
					gap: '1rem',
					borderBottom: `1px solid ${theme.colors?.grey300}`,
					'&:hover': { cursor: 'pointer' },
				},
				opinionLibraryList: {
					height: '42rem',
					width: '550px',
					overflow: 'auto',
					/* Firefox에 대한 스크롤바 스타일 설정 */
					'&::-webkit-scrollbar': {
						width: '8px' /* 스크롤바의 너비 */,
					},

					'&::-webkit-scrollbar-track': {
						backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
					},

					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
						borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
					},

					'&::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
					},
				},
				resultBoxOpinion: {
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: 'row',
					gap: '4rem',
					minWidth: '100%',
					padding: '0rem 1.25rem',
					maxHeight: '83%',
					'& > .MuiPaper-root': {
						width: '30.625rem',
						// padding: '1rem',
					},
				},
				libraryList: { display: 'flex', flexDirection: 'column' },
				edit: {
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					gap: '1rem',
				},
				title: { display: 'flex', alignItems: 'center', gap: '1rem', width: '100%' },
				content: { display: 'flex', alignItems: 'top', gap: '1rem', width: '100%' },
			},
			testDecisionSetting: {
				base: {
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				},
				settingStack: {
					display: 'flex',
					flexDirection: 'row',
					gap: '0.625rem',
					width: '100%',
					padding: '1.5rem',
					'& .MuiSvgIcon-root': {
						display: 'none',
					},
				},
				settingBox: {
					width: '30rem',
					height: '100%',
					'& .MuiPaper-root': {
						padding: '1.25rem',
					},
				},
				headerTitle: {
					display: 'flex',
					flexDirection: 'row',
					paddingBottom: '1rem',
				},
				row: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginBottom: '0.75rem',
					padding: '0rem 1rem',
				},
				headerRow: {
					width: '100%',
					height: '1.875rem',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					backgroundColor: theme.colors?.grey700,
					borderBottom: `2px solid ${theme.colors?.grey300}`,
					padding: '1rem',
					marginBottom: '1rem',
				},
				testName: {
					width: '150px',
				},
				inputContainer: {
					width: '50px',
				},
			},
			resultCard: {
				resultStack: {
					width: '100%',
				},
				resultBox: {
					minWidth: '18rem',
					width: '100%',
					height: '100%',
					'& .MuiPaper-root': {
						paddingLeft: '1.25rem',
						paddingRight: '1.25rem',
					},
				},
				resultBoxNarrow: {
					minWidth: '18rem',
					width: '100%',
					'& .MuiPaper-root': {
						paddingLeft: '1.25rem',
						paddingRight: '1.25rem',
					},
				},
				alignRow: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					width: '15.625rem',
					minHeight: '2.8125rem',
					gap: '0.625rem',
				},
				testClassCodeBox: {
					width: '100%',
					height: '1.875rem',
					paddingLeft: '1.875rem',
					backgroundColor: theme.colors?.grey200,
				},
				decisionBox: {
					display: 'flex',
					width: '100%',
					height: '2rem',
					padding: '0rem 1rem',
					backgroundColor: theme.colors?.primary300,
				},
				cellBox: {
					width: '100%',
					height: '1.875rem',
					display: 'flex',
					alignItems: 'center',
				},
				cellBoxA: {
					// 비정상
					width: '100%',
					height: '1.875rem',
					backgroundColor: '#D91A1A1A',
					display: 'flex',
					alignItems: 'center',
				},
				cellBoxR: {
					// 의심
					width: '100%',
					height: '1.875rem',
					backgroundColor: '#F790371A',
					display: 'flex',
					alignItems: 'center',
				},
				cellLeft: {
					width: '50%',
				},
				cellRight: {
					width: '50%',
				},
				icon: { marginRight: '0.5rem', color: theme.colors?.grey450 },
				iconBlack: { marginRight: '0.5rem', color: '#230B34' },
				border: { borderBottom: `1px solid ${theme.colors?.grey250}` },
			},
			setting: {
				base: {
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'row',
				},
				settingStack: {
					display: 'flex',
					flexDirection: 'row',
					gap: '0.625rem',
					width: '100%',
					padding: '2.5rem',
					'& .MuiSvgIcon-root': {
						display: 'none',
					},
				},
				settingBox: {
					width: '40rem',
					height: '100%',
					'& .MuiPaper-root': {
						padding: '1.25rem',
						/* Firefox에 대한 스크롤바 스타일 설정 */
					},
				},
				settingGrid: {
					height: '100%',
					overflowY: 'scroll',
					'&::-webkit-scrollbar': {
						width: '8px' /* 스크롤바의 너비 */,
					},

					'&::-webkit-scrollbar-track': {
						backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
					},

					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
						borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
					},

					'&::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
					},
				},

				testClassCodeBox: {
					width: '100%',
					height: '1.875rem',
					paddingLeft: '1.875rem',
					backgroundColor: theme.colors?.grey700,
				},

				cellBox: {
					width: '100%',
					height: '5rem',
					display: 'flex',
					alignItems: 'center',
				},
			},
			decisionCard2: {
				resultStack: {},
				resultBox: {
					padding: '20px',
					'& .MuiPaper-root': {
						padding: '1rem',
					},
				},
				resultBoxNarrow: {
					padding: '20px',
					'& .MuiPaper-root': {
						padding: '1rem',
					},
				},
				alignRow: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '10px',
					width: '100%',
				},
				testClassCodeBox: {
					width: '100%',
					height: '2rem',
					paddingLeft: '1rem',
					// marginBottom: '0.5rem',
					backgroundColor: theme.colors?.primary300,
				},
				decisionBox: {
					display: 'flex',
					width: '100%',
					height: '2rem',
					padding: '0rem 1rem',
					backgroundColor: theme.colors?.secondaryMain,
					// margin: '0.3rem 0rem',
				},
				cellBox: {
					width: '100%',
					height: '1.75rem',
					display: 'flex',
					alignItems: 'center',
				},
				cellBoxA: {
					// 비정상
					width: '100%',
					height: '1.875rem',
					backgroundColor: '#D91A1A1A',
					display: 'flex',
					alignItems: 'center',
				},
				cellBoxR: {
					// 의심
					width: '100%',
					height: '1.875rem',
					backgroundColor: '#F790371A',
					display: 'flex',
					alignItems: 'center',
				},
				cellBoxAR: {
					width: '100%',
					height: '1.75rem',
					display: 'flex',
					alignItems: 'center',
				},
				cellLeft: {
					width: '60%',
				},
				cellRight: {
					width: '40%',
				},
				icon: { marginRight: '0.5rem', color: theme.colors?.grey450 },
				iconBlack: { marginRight: '0.5rem', color: '#230B34' },
			},
			workListInfo: {
				resultStack: {
					display: 'grid',
					gridTemplateColumns: '18.4375rem 17.1875rem 13rem',
					// rowGap: '0.2rem',
					margin: '0.3rem 0rem',
				},
				resultStackDecisionTab: {
					display: 'grid',
					gridTemplateColumns: '18.4375rem 17.1875rem 13rem 18.4375rem 17.1875rem 13rem',
					// rowGap: '0.2rem',
					margin: '0.3rem 0rem',
				},
				alignRow: {
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
					alignItems: 'flexStart',
					height: '3rem',
					columnGap: '0.5rem',
				},
				infoBox: {
					height: '1rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '0.2rem',
				},
				icon: { color: theme.colors?.grey450 },
				iconBlack: { marginRight: '0.5rem', color: '#230B34' },
				tagBox: {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '0.5rem',
					height: '1.5rem',
					lineHeight: '1.5rem',
				},
				tagIcon: {
					width: '0.25rem',
					height: '0.25rem',
					borderRadius: '100%',
					backgroundColor: '#656565',
				},
			},

			statusTag: {
				iconN: {
					// 정상
					width: '0rem',
					// visibility: 'hidden',
					fontSize: '0.625rem',
				},
				iconA: {
					// 비정상
					width: '1.875rem',
					color: theme.colors?.errorMain,
					fontSize: '0.625rem',
				},
				iconR: {
					// 의심
					width: '1.875rem',
					color: theme.colors?.warningMain,
					fontSize: '0.625rem',
				},
				iconB: {
					// 결과없음
					width: '0rem',
					color: theme.colors?.grey500,
					fontSize: '0.625rem',
					// visibility: 'hidden',
				},
				iconU: {
					// 식별불가
					width: '1.875rem',
					color: '#878790',
					fontSize: '0.625rem',
				},
			},

			decisionTag: {
				tagNormal: {
					display: 'inline-flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '3.25rem',
					height: '1.5rem',
					borderRadius: '0.5rem',
					backgroundColor: theme.colors?.successLight,
					fontSize: '0.8125rem',
					fontFamily: fontFamily,
					color: theme.colors?.successDark,
					whiteSpace: 'nowrap', // 줄바꿈 방지
				},
				tagAbnormal: {
					display: 'inline-flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '3.25rem',
					height: '1.5rem',
					borderRadius: '0.5rem',
					backgroundColor: theme.colors?.errorLight,
					fontSize: '0.8125rem',
					fontFamily: fontFamily,
					color: theme.colors?.errorMain,
					whiteSpace: 'nowrap', // 줄바꿈 방지
				},
				tagWarning: {
					display: 'inline-flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '3.2rem',
					height: '1.5rem',
					padding: '0.3rem',
					borderRadius: '0.5rem',
					backgroundColor: theme.colors?.warningMain,
					fontSize: '0.8125rem',
					fontFamily: fontFamily,
					color: theme.colors?.errorMain,
					whiteSpace: 'nowrap', // 줄바꿈 방지
				},
				tagError: { fontSize: '0.8125rem', backgroundColor: 'yellow' },
			},
			decisionResultCard: {
				Paper: { padding: '1.25rem' },
				icon: { marginRight: '2.75rem' },
				errorRow: {
					'& .error-row': {
						backgroundColor: theme.colors?.errorBg,
					},
					'& .warning-row': {
						backgroundColor: theme.colors?.warningBg,
					},
					'& .no-row': {
						backgroundColor: theme.colors?.grey150,
					},
					'& .error-row:hover': {
						// backgroundColor: theme.colors?.errorLight,
					},
				},
				datagridNonBorder: {
					'& .MuiDataGrid-cell': {
						borderBottom: 'none',
						// justifyContent: 'end',
					},
					'& .MuiDataGrid-columnHeaderTitleContainer': {
						// justifyContent: 'start',
					},
				},
				decisionResultCard: {
					// 헤더 및 호버 추가
					'& .MuiDataGrid-columnHeaderTitleContainer': {
						// justifyContent: 'start',
					},
					'& .MuiDataGrid-row:hover': {
						boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
					},
					//적용
					// '& .MuiDataGrid-row.Mui-selected': {
					//     backgroundColor: theme.colors?.primary400,
					// },
					//icon열 마진 제거
					'& .MuiSvgIcon-root': {
						margin: 0,
					},
					// 열 속성을 위해 추가
					'& .decisionCode2': {
						color: theme.colors?.errorMain,
					},
					'& .decisionAbnormal': {
						color: theme.colors?.errorMain,
					},
					// 판정 선택 확인용 추가
					'& .decision': {
						color: theme.colors?.grey500,
						padding: '0px 2px',
						minWidth: '22px',
						maxWidth: '80px',
						borderRadius: '5px',
						height: '20px',
					},
					'& .decision-select-Abnormal': {
						color: theme.colors?.successMain,
						padding: '0px 2px',
						minWidth: '22px',
						maxWidth: '80px',
						borderRadius: '5px',
						height: '20px',
					},
					'& .decision-select-Normal': {
						color: theme.colors?.successMain,
						padding: '0px 2px',
						minWidth: '22px',
						maxWidth: '80px',
						borderRadius: '5px',
						height: '20px',
					},
					// 판정 선택 차이(로직 / 사람) 확인용 추가
					'& .differentValueBackgroudColor': {
						backgroundColor: theme.colors?.grey300,
					},
					'& .differentValueColor': {
						color: theme.colors?.warning200,
					},
					// 그리드 내용물 경계선 생성용
					'& .borderTop': {
						borderTop: `1px solid ${theme.colors?.grey250}`,
					},
					'& .borderBottom': {
						borderBottom: `1px solid ${theme.colors?.grey250}`,
					},
				},
				alignRow: {
					display: 'flex',
					justifyContent: 'center',
					width: '20rem',
				},
				alignColumnStart: {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
					height: '6.25rem',
					gap: '1rem',
				},
				listAlign: {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
					gap: '0.625rem',
				},
				infoList: {
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					gap: '2.75rem',
				},
				alignBtn: {
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '1.25rem',
				},
				textBox: {
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
				},
				checkBox: {
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '21.625rem',
					marginLeft: '5px',
				},
				buttonStatus: {
					// visibility: 'hidden',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: '0.625rem',
				},
				buttonStatusHidden: {
					display: 'none',
				},
			},
			NRBCPopup: {
				NRBCPanel: {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '15rem',
				},
				btnarea: {
					'& .MuiButton-root': {
						minWidth: '1.8rem',
						padding: '0.8rem 0.9rem',
						border: '1px solid',
						borderColor: `${theme.colors?.primary200 + 75}`,
						boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
					},
				},
				deleteBtn: {
					width: '2.1875rem',
					height: '2.5rem',
					backgroundColor: '#D8D8D8',
					'&:hover': {
						backgroundColor: '#D8D8D8',
					},
					'&:active': {
						backgroundColor: '#683DCE',
					},
				},
				numberBtnLine: {
					display: 'flex',
					flexDirection: 'row',
					width: '15rem',
					height: '2.5rem',
					justifyContent: 'space-between',
				},
				numberBtn: {
					width: '2.1875rem',
					height: '2.5rem',
					backgroundColor: '#D8D8D8',
					'&:hover': {
						backgroundColor: '#D8D8D8',
					},
					'&:active': {
						backgroundColor: '#683DCE',
					},
				},
				inputBtn: {
					width: '4.375rem',
					height: '2.5rem',
					padding: '0.6rem',
				},
			},
			imageCard: {
				image: {
					width: '100%',
					height: '100%',
					objectFit: 'contain',
				},
				lowConfidenceImage: {
					border: '1px solid red',
				},
				buttonContainer: {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				},
				ratioBtn: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					padding: '0.2rem 0.5rem',
					gap: '0.5rem',
				},
				disabledRatioBtn: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					padding: '0.2rem 0.5rem',
					backgroundColor: 'none', // 의견필요
				},
				decisionNormal: {
					color: theme.palette?.primary['main'],
				},
				decisionAbnormal: {
					color: theme.palette?.error['main'],
				},
				modal: {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},
				modalImage: {
					// minWidth: '20vw',
					// maxWidth: '20vw',
				},
			},
		},
		views: {
			example: {
				base: {
					height: '100%',
				},
				baseLeftContainer: {
					minWidth: '60rem',
				},
				baseRightContainer: {
					flexGrow: 1,
				},
				searchPanel: {
					base: {
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						minWidth: '56rem',
						height: '8.125rem',
						padding: '1.25rem 1.875rem 1.5625rem 3.125rem',
					},
					left: {
						flexBasis: '22rem',
					},
					leftTop: {
						height: '3rem',
					},

					leftBottom: {
						width: '3rem',
					},
					middleTop: {
						height: '3rem',
					},
					buttonContainer: {
						flexBasis: '6rem',
					},
					button: {
						width: '6.25rem',
						height: '5.3125rem',
					},
				},
				searchPanelCheckboxes: {
					title: {
						width: '3.75rem',
					},
					checkboxContainer: { width: '19.0625rem' },
				},
				workListResultPanel: {
					itemCode: {
						flex: 1,
						minWidth: '5rem',
					},
					testName: {
						width: '15rem',
					},
					result: {
						width: '5rem',
						textAlign: 'right',
					},
				},
			},
			worklist: {
				base: {
					width: '100%',
					height: '100%',
					display: 'flex',
					flexDirection: 'row',
				},
				flexCenter: {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '8rem',
				},
				baseRightEmpty: {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					minHeight: `calc(100vh - 15rem)`,
					height: '100%',
				},
				baseLeftContainer: {
					minWidth: '60rem',
				},
				baseRightContainer: {
					minWidth: '60rem',
				},
				resultCardContainer: {
					display: 'flex',
					justifyContent: 'space-between',
					overflow: 'auto',
					padding: '0rem 2.5rem 1.5rem 1.875rem',
					overflowY: 'auto',
					overflowX: 'hidden',
					/* Firefox에 대한 스크롤바 스타일 설정 */
					'&::-webkit-scrollbar': {
						width: '8px' /* 스크롤바의 너비 */,
					},

					'&::-webkit-scrollbar-track': {
						backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
					},

					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
						borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
					},

					'&::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
					},
				},
				searchPanel: {
					left: {
						flexBasis: '20rem',
					},
					leftTop: {
						height: '3rem',
					},

					leftBottom: {
						width: '3rem',
					},
					middleTop: {
						height: '3rem',
						width: '398px',
					},
					buttonContainer: {
						flexBasis: '6rem',
					},
					button: {
						width: '100%',
						height: '100%',
						marginTop: '0.2rem',
						marginBottom: '0.2em',
					},
				},
				searchPanelCheckboxes: {
					title: {
						width: '3rem',
					},
				},
				workListResultPanel: {
					itemCode: {
						flex: 1,
						minWidth: '5rem',
					},
					testName: {
						width: '15rem',
					},
					result: {
						width: '5rem',
						textAlign: 'right',
					},
				},
				workListGrid: {
					height: 'calc(100vh - 200px)',
					width: '60.625rem',
					padding: '0rem 2.5rem',
				},
				dataGridBox: {
					height: '100%',
				},
				result: {
					width: '10rem',
				},
				centerAlignRow: {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
				},
				icon: { color: theme.colors?.grey450 },
				arrowIcon: {
					color: theme.colors?.grey450,
					'&:hover': {
						cursor: 'pointer',
						color: theme.colors?.primaryMain,
					},
				},
				button: {
					paddingLeft: '0.9375rem',
					paddingRight: '0.9375rem',
					height: '2.5rem',
					whiteSpace: 'nowrap', // 줄바꿈 방지
				},
			},
			result: {
				base: {
					base: {
						width: '100%',
						height: '100%',
					},
					contents: {
						display: 'flex',
						justifyContent: 'space-between',
						minWidth: '117.5rem',
						height: '100%',
						overflowY: 'auto',
						overflowX: 'hidden',
						/* Firefox에 대한 스크롤바 스타일 설정 */
						'&::-webkit-scrollbar': {
							width: '8px' /* 스크롤바의 너비 */,
						},

						'&::-webkit-scrollbar-track': {
							backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
						},

						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
							borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
						},

						'&::-webkit-scrollbar-thumb:hover': {
							backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
						},
					},
					DecisionBox: {
						display: 'flex',
						justifyContent: 'space-between',
						maxHeight: '62.1875rem',
						height: 'calc(100vh - 8rem)',
						minWidth: '1840px',
						overflowY: 'auto',
						overflowX: 'hidden',
					},

					DecisionContents: {
						display: 'flex',
						justifyContent: 'space-between',
						maxHeight: '62.1875rem',
						height: 'calc(100vh - 8rem)',
						minWidth: '1920px',
						overflowY: 'auto',
						overflowX: 'hidden',

						/* Firefox에 대한 스크롤바 스타일 설정 */
						'&::-webkit-scrollbar': {
							width: '8px' /* 스크롤바의 너비 */,
						},

						'&::-webkit-scrollbar-track': {
							backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
						},

						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
							borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
						},

						'&::-webkit-scrollbar-thumb:hover': {
							backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
						},
					},
					leftPanel: {
						height: `calc(100vh - 121px)`,
						minWidth: '35rem',
						paddingLeft: '2.5rem',
						overflowY: 'auto',
						justifyContent: 'space-between',
						/* Firefox에 대한 스크롤바 스타일 설정 */
						'&::-webkit-scrollbar': {
							width: '8px' /* 스크롤바의 너비 */,
						},

						'&::-webkit-scrollbar-track': {
							backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
						},

						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
							borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
						},

						'&::-webkit-scrollbar-thumb:hover': {
							backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
						},
					},

					DecisionPanel: {
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',

						height: '100%',
						gap: '1rem',
						'& .MuiPaper-root': { width: '27.5rem', height: '100%' },
					},
					DecisionPanelNarrow: {
						width: '27.5rem',
						height: 'fit-content',
					},
					opinionPanelNarrow: {
						'& .MuiPaper-root': { width: '27.5rem', height: '100%' },
					},
					opinionLibraryPanel: {
						height: '100%',
						'& .MuiPaper-root': { width: '30.625rem', height: '100%' },
					},
					leftOpinionPanel: {
						flexBasis: '15rem',
					},
					rightPanel: {
						// flexGrow: 1,
						backgroundColor: theme.colors?.page,
						height: 'calc(100vh - 121px)',
						minWidth: '82.5rem',
						marginLeft: '1.875rem',
						paddingRight: '2.1875rem',
						display: 'flex',
						flexDirection: 'column',
						borderRadius: borderRadius,
					},
					rightInnerPanel: {
						height: `calc(100vh - 12rem)`,
						paddingRight: '5px',
						overflowY: 'auto',
						'& .focus': {
							backgroundColor: '#F8F5FF',
							border: `2px solid ${theme.colors?.primaryMain}`,
						},
						/* Firefox에 대한 스크롤바 스타일 설정 */
						'&::-webkit-scrollbar': {
							width: '8px' /* 스크롤바의 너비 */,
						},

						'&::-webkit-scrollbar-track': {
							backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
						},

						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
							borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
						},

						'&::-webkit-scrollbar-thumb:hover': {
							backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
						},
					},
					alignRowBottom: {
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						minHeight: '7.25rem',
						marginBottom: '0.3125rem',
					},
					alignSide: {
						display: 'flex',
						alignItems: 'center',
						height: '2.25rem',
						gap: '1.25rem',
						marginRight: '0.3125rem',
					},
					alignRow: {
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						height: '2.25rem',
						width: '28rem',
					},
					alignTitle: {
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						alignItems: 'center',
						gap: '0.5rem',
						padding: '20px',
						height: '2.8125rem',
					},
					btnBox: {
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginRight: '1.25rem',
					},
					microscopeBtn: {
						gap: '0.625rem',
						height: '2.25rem',
						whiteSpace: 'nowrap',
						padding: '0rem 0.9375rem',
					},
					allCellViewBtn: {
						gap: '0.625rem',
						height: '2.25rem',
						whiteSpace: 'nowrap',
						padding: '0rem 0.9375rem',
					},
				},
			},
			microscope: {
				imgBox: {
					width: 'calc(100vh - 22.5rem)',
					height: 'calc(100vh - 22.5rem)',
					border: '1px solid red',
				},
				slideCard: {
					width: 'fit-content',
					position: 'absolute',
					top: '15rem',
					left: '35rem',
					background: 'white',
					borderRadius: '1rem',
					zIndex: 1,
				},
				control: {
					position: 'absolute',
					bottom: '10rem',
					right: '10rem',
					zIndex: 1,
				},
				test: {
					width: '100%',
					height: '100%',
				},
			},
			allCellView: {
				container: {
					/* Firefox에 대한 스크롤바 스타일 설정 */
					'&::-webkit-scrollbar': {
						width: '8px' /* 스크롤바의 너비 */,
					},

					'&::-webkit-scrollbar-track': {
						backgroundColor: '#E3E3E3' /* 트랙의 배경색 */,
					},

					'&::-webkit-scrollbar-thumb': {
						backgroundColor: '#B0B0B0' /* 핸들의 배경색 */,
						borderRadius: '6px' /* 핸들의 모서리를 둥글게 */,
					},

					'&::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#B0B0B0' /* 사용 중일 때의 배경색 변경 */,
					},
				},
				imgBox: {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100px',
					padding: '1rem',
					border: '1px solid #D1D1D1',
					objectFit: 'scale-down',
				},
				img: { objectFit: 'contain', display: 'flex' },
			},
		},
		component: {
			custom: {
				basePanel: {
					paper: {
						display: 'flex',
						flexDirection: 'column',
						border: `1px solid ${theme.divider}`,
						borderRadius: borderRadius,
					},
				},
			},
			datepicker: {
				dateRange: {
					datePickerContainer: {
						display: 'flex',
						justifyContent: 'space-between',
						width: '24.6875rem',
					},
					inputField: {
						width: '11.25rem',
					},
				},
			},
		},
	};
}
